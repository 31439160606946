import React, {useEffect, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import CustomButton from "../Common/CustomButton/CustomButton";
import {useHistory} from 'react-router-dom';


export default function (props) {
	const history = useHistory();
	const renderLocation = (permalink)=>{
		props.reactGA.event({
			category: "Location",
			action: "Select",
			label:permalink
		})
		history.push(`/${props.brand.link}/${props.brandCondition}/${permalink}/bmw${history.location.search}`);
	}
	const [images,setImages] = useState([]);

	useEffect(()=>{
		let newImages = [];
		if(props.fleetLocations != null){
			let width = props.fleetLocations.length * 10
			if(props.fleetLocations.length === 1){
				width = '50'
			}
			props.fleetLocations.map(item=>{
				newImages.push({
					url: item.image,
					title: item.name,
					permalink:item.permalink,
					width:`${width}%`,
					renderFunction : renderLocation
				})
				return item;
			})
		}else{
			let width = props.brand.locations.length * 10
			if(props.brand.locations.length === 1){
				width = '50'
			}
			props.brand.locations.map(item=>{
				newImages.push({
					url: item.image,
					title: item.name,
					width:`${width}%`,
					permalink:item.permalink,
					renderFunction : renderLocation
				})
				return item;
			})
		}
		setImages(newImages);
	},[props.brand.locations])



	return(<React.Fragment>
		<BodyContainer {...props} childComponent={<React.Fragment>
			<React.Fragment>
				<div className={'col'}>
					<h4 style={{marginTop:'10px',fontSize:'18px'}}>Maak een afspraak met jouw vertrouwde Jorssen salesadvisor tijdens de Secret salesnight en <span style={{textDecoration:'underline'}}>reserveer jouw productieslot</span> op deze uitzonderlijke <span style={{textDecoration:"underline"}}>koopavond</span>.<br/>
					<span style={{fontWeight:'400'}}>Ons volledig team van Jorssen Zuid en Jorssen Noord zal je graag verwelkomen in<br/>Aartselaar om kennis te maken met jouw nieuwe BMW iX of i4.</span></h4>
				</div>
				<CustomButton carCondition={props.brandCondition} className={'margin-images'}  images={images}/>
				<div style={{textAlign:'center',marginTop:'20px'}} className={'col'}>
						<span style={{fontWeight:'400'}}>Wens je de BMW iX en i4 vrijblijvend te ontdekken op zaterdag 19/6 of zondag 20/6?<br/>Schrijf je dan <a href={'https://www.bmwdealer-form.be/bmw-born-electric-the-ix-and-the-i4-jorssen-nl'} style={{textDecoration:'underline'}} target={'_blank'}>hier</a> in voor het Avant-première weekend.</span>
				</div>
			</React.Fragment>
		</React.Fragment>
		} />
	</React.Fragment>)
}
