import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import {CSSTransition} from "react-transition-group";
import Button from "@material-ui/core/Button";
import {carBrands} from "../../AppConstants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Markup from "interweave";

export default function (props) {
  const history = useHistory();
  useEffect(()=>{
    history.push("/bmw%20jorssen/new");
    setBrands(props.brands);
    if(props.brands.length > 0){
      setLoaderVisibility(false);
    }
  },[props.brands])
  const [loader,setLoaderVisibility] = useState(true);
  const [brands,setBrands] = useState([]);
  const isMobile = useMediaQuery('(max-width:769px)');
  return(<React.Fragment>
       <div style={{paddingTop:isMobile ? '0':'80px'}}  className={"homepage-content white"}>
         <div className={"container"}>
           <div className={"row"}>
             {loader  &&  <div style={{display:'flex',justifyContent:'center'}} className={"col"}>
               <CircularProgress style={{width:'90px',color:'gray'}} />
             </div>}
             {brands.length !== 0 ?
                <div className={"col-lg-12 col-md-12 col-sm-12 brands-container"}>
                  {props.homePageText !=null ? <Markup    content={props.homePageText}/> :''}
             <CSSTransition
                in={!loader }
                timeout={900}
                classNames="list-transition"
                appear
                unmountOnExit
             >
               <div style={{paddingTop:'80px'}} key={props.brand} className={"row"}>
                 {brands.map(item=>{
                   return <div key={item.name} className={'col-lg-12 col-md-12 col-sm-12'}>
                     <div style={{height:isMobile? '200px':'230px'}} className={'logo-container'}>
                     <div style={{height:isMobile?'100px':'150px'}} className={'logo-container-image'}>
                         <img   alt={item.name} src={item.image} />
                     </div>
                       <Button className={`${item.name.toLowerCase()}-button`} onClick={()=>{
                         props.reactGA.event({
                           category: "Brand",
                           action: "Select",
                           label: item.name.toLowerCase()
                         })
                         history.push(`/${item.name.toLowerCase()}/new${history.location.search}`)}} style={{cursor:'pointer',fontFamily:'obvia,sans-serif'}}>Maak afspraak</Button>
                     </div>
                   </div>
                 })}
               </div>
             </CSSTransition>
             </div>: ''}
           </div>
         </div>
       </div>
     </React.Fragment>
  )
}
