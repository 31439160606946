import React, {useEffect, useRef, useState} from "react";
import Badge from '@material-ui/core/Badge';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Transition} from "react-transition-group";
import { useHistory } from "react-router-dom";
import {carBrands} from "../../AppConstants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius:0,
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1),
			maxWidth:'230px',
		},
	},
	hide:{
		display:'none'
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	large: {
		objectFit:'cover',
		width: theme.spacing(17),
		height: theme.spacing(17),
	},
}));
const duration = 320;

const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0,
}

const transitionStyles = {
	entering: { opacity: 1 },
	entered:  { opacity: 1 },
	exiting:  { opacity: 0 },
	exited:  { opacity: 0 },
};
export default function (props) {
	const classes = useStyles();

	const StyledBadge = withStyles((theme) => ({
		badge: {
			backgroundColor: props.employee.period == null || props.employee.period.length === 0 ? '#b80c0c' :'#44b700',
			color: props.employee.period == null || props.employee.period.length === 0 ? '#b80c0c' :'#44b700',
			boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: `$ripple 1.2s ${props.employee.period === null || props.employee.period.length === 0	  ? 'once' : 'infinite'} ease-in-out`,
				border: '1px solid currentColor',
				content: '""',
			},
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1,
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0,
			},
		},
	}))(Badge);
	const [time,setTime] = useState([]);
	const [isLoaded,setIsLoaded] = useState(false);
	const [employeeImage,setEmployeeImage] = useState('');
	const [inProp, setInProp] = useState(false);
	const history = useHistory();
	const isMobile = useMediaQuery('(max-width:520px)');
	useEffect(()=>{
		setIsLoaded(false);
		getEmployeeTime();
		const img = new Image();
		img.src = props.employee.image != false ? props.employee.image : '/assets/img/manplaceholder.png'; // by setting an src, you trigger browser download
		img.onload = () => {
			// when it finishes loading, update the component state
			setIsLoaded(true);
			setEmployeeImage(img.src);
			setTimeout(()=>{
				setInProp(!inProp)
			},0)
		}
	},[props.employee])
	const getEmployeeTime = ()=>{
		if(props.employee && props.employee.period){
			setTime(props.employee.period);
		}
	}
	const employeeInfoRef = useRef(null);

	return(
		 <div>
			 {isLoaded ? <React.Fragment>
				 <Transition  in={inProp} timeout={duration}>
					 {state=>(
							<div style={{
								...defaultStyle,
								...transitionStyles[state]
							}}>
								<Card   style={{background:`linear-gradient(180deg, #0C2E40 50%,white 50%)`}} className={`${classes.root } employeeContainer`}>
									<CardActionArea onClick={(e)=>{
										employeeInfoRef.current.classList.contains("hide") ? employeeInfoRef.current.classList.remove("hide") : employeeInfoRef.current.classList.add("hide");
									}}>
										<div className={"imageContainer"}>
											<CardMedia
												 component="img"
												 alt={props.employee.name}
												 height="150px"
												 id={`${props.employee.name.split(" ").join('')}`}
												 style={{objectFit:"cover",width:'150px',borderRadius:'50%',fontFamily:'obvia,sans-serif'}}
												 image={employeeImage}
												 title={props.employee.name}
											/>
										</div>
										<StyledBadge
											 overlap="circle"
											 anchorOrigin={{
												 vertical: 'bottom',
												 horizontal: 'right',
											 }}
											 style={{position:'absolute',right:'27%',bottom:'50%'}}
											 variant="dot"
										/>

										<CardContent style={{textAlign:'center'}}>
											<Typography style={{fontFamily:'obvia,sans-serif'}} gutterBottom variant="h5" component="h2">
												{props.employee.name}
											</Typography>
											<Typography style={{fontFamily:'obvia,sans-serif'}} variant="body2" color="textSecondary" component="p">
												{props.employee.title}
											</Typography>
										</CardContent>
									</CardActionArea>

								</Card>
								<Card ref={employeeInfoRef}   style={{flexDirection:'column',backgroundColor:'#FAFAFA',overflowX:'hidden',overflowY:'scroll',maxHeight:'450px'}}  className={classes.root}>

									{ time && time.length > 0 ? time.map(item=>{
										return <div  key={props.employee.name + Math.random()} style={{marginLeft:"auto",marginRight:'auto',}} className={'time'}>
											<span style={{marginLeft:'20px',marginRight:'20px'}}>{item.timeSlot}</span>
											<Button disabled={!item.available}  onClick={(e)=>{
												props.reactGA.event({
													category: "Booking Calendar",
													action: "Select Timeslot",
													label:item.timeSlot
												})
												history.push(`${history.location.pathname}/booking${history.location.search}`,{carData:props.carData,employee:props.employee,brand:props.brand,location:props.location,currentDate:props.date,timeSlot:item.timeSlot})
												}} value={props.employee.name}  style={{margin:isMobile ? 'unset':'11px',width:'fit-content',alignSelf:'center',backgroundColor:`${item.available  ? '#0C2E40':''}`,color:`${item.available ? 'white':''}`}} color={"primary"} variant="contained">{item.available ? 'Boek' :'Bezet'}</Button></div>
									}):<div style={{textAlign:'center'}}>Geen Afspraak</div>}

								</Card>
							</div>
					 )}
				 </Transition>
				 </React.Fragment> :''}
		 </div>

	)
}
