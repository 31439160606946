import React from "react";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import LocationFooter from "../LocationFooter/LocationFooter";
export default function (props) {
    return(<footer className={props.theme === "dark" ? 'footerContainer' :'footerContainerLight'} style={{backgroundColor:props.backgroundColor}} >
        <div style={{paddingTop:'50px'}} className={'container'}>
            <div className={'row'}>
              {props.brand ?<React.Fragment>
                <div className={'col-lg-6 col-sm-12'}>
                  {props.brand.map(location=>{
                    return <LocationFooter key={Math.random()} location={{locationName:location.name,locationURL:location.url}} tele={location.number} email={location.email}/>
                  })}
                </div>
                  {props.socialMedia &&
                  <div style={{textAlign:'center'}} className={'col-lg-6 col-sm-12'}>
                      <a target={'_blank'} href={props.socialMedia.facebook_url}> <FacebookIcon  fontSize="large" /></a>
                      <a target={'_blank'} href={props.socialMedia.linkedin_url}><LinkedInIcon  fontSize="large"/></a>
                      <a target={'_blank'} href={props.socialMedia.instagram_url}><InstagramIcon  fontSize="large"/></a>
                  </div>}

                <div className={'col-lg-6 col-sm-12'} >
                  <a target={'_blank'} href={'https://jorssen.be/mini/cookies/'}>Cookie Policy</a>
                  <a style={{marginLeft:'20px'}} target={'_blank'} href={'https://jorssen.be/mini/privacy-policy/'}>Privacy Policy</a>
                </div>

              </React.Fragment>:''}
            </div>
        </div>
    </footer>)
}
