import React, {useEffect, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import CarComponent from "./CarComponent";
import Grid from "@material-ui/core/Grid";
import {getCarsByBrand} from "../HTTPS/apisInterface";


export default function (props) {
	const [cars,setCars] = useState([]);

	useEffect(()=>{
		getCarsByBrand(props.brand.link,props.brand.condition).then((res)=>{
				 setCars(res);
			 }
		)
	},[]);
	return (<React.Fragment>
		<BodyContainer {...props} childComponent={
			<React.Fragment>
				<div className={'col'}>
					<Grid  justify="flex-start"
								 alignItems="center" style={{marginTop:'20px'}} container spacing={4}>
						{cars.map(item=>{
							return 		<Grid key={item.name} item xs={6} sm={6} md={4} lg={3} >
								<CarComponent reactGA={props.reactGA}  carObject={item}/>
							</Grid>
						})}

					</Grid>
				</div>
			</React.Fragment>}/>
	</React.Fragment>)
}
